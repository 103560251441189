import React from 'react';
import {
  ModalBody,
  ModalContainer,
  ModalHeader,
  ModalRoot,
} from '@mentimeter/ragnar-ui/modal';

interface ModalProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
}

export function Modal({ children, open, onClose }: ModalProps) {
  return (
    <ModalRoot
      open={open}
      onOpenChange={(isOpen) => {
        if (!isOpen) onClose();
      }}
    >
      <ModalContainer
        width={700}
        height="80vh"
        onEscapeKeyDown={(e) => {
          e.stopPropagation();
          onClose();
        }}
      >
        <ModalHeader
          size="medium"
          dismissLabel="Close experiments dialog"
          onDismissClick={onClose}
        >
          Experiments
        </ModalHeader>

        <ModalBody>{children}</ModalBody>
      </ModalContainer>
    </ModalRoot>
  );
}
