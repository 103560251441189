import React from 'react';
import type { BoxT } from '@mentimeter/ragnar-ui/box';
import { Box } from '@mentimeter/ragnar-ui/box';

export function Heading({ children, ...rest }: React.PropsWithChildren<BoxT>) {
  return (
    <Box
      width="100%"
      borderStyle="solid"
      borderBottomWidth={1}
      borderColor="border"
      mb={2}
      {...rest}
    >
      {children}
    </Box>
  );
}
