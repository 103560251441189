import React from 'react';
import { ClipboardIcon } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui/box';
import type { RagnarButtonState } from '@mentimeter/ragnar-ui/button';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Text } from '@mentimeter/ragnar-ui/text';
import type { FlagT } from '../../types';
import { FEEDBACK_DURATION } from '../../constants';

interface ImportButtonProps {
  clearAll(): void;
  flags: FlagT[];
  onImportSuccess: (flags: FlagT[]) => void;
}

export function ImportButton({
  flags,
  clearAll,
  onImportSuccess,
}: ImportButtonProps) {
  const [status, setStatus] = React.useState<RagnarButtonState>();
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

  return (
    <Box alignItems="center">
      <Button
        width="100%"
        state={status}
        size="compact"
        iconLeading={ClipboardIcon}
        onClick={async () => {
          try {
            if (!navigator.clipboard?.readText) {
              throw new Error('Your browser does not support this feature');
            }

            const rawClipboardText = await navigator.clipboard.readText();
            const parsedFlags = JSON.parse(rawClipboardText) as FlagT[];
            const validFlags = parsedFlags.filter((flag) =>
              flags.some(({ key }) => key === flag.key),
            );

            if (validFlags.length !== 0) {
              clearAll();
              onImportSuccess(validFlags);
            }

            setStatus('success');
          } catch (e) {
            if (e instanceof Error) {
              setErrorMessage(e.message);
            }
            setStatus('error');
          } finally {
            setTimeout(() => setStatus(undefined), FEEDBACK_DURATION);
          }
        }}
      >
        Import from clipboard
      </Button>

      {status === 'error' && (
        <Text color="negative" fontSize={1}>
          {/* eslint-disable-next-line menti-react-jsx/no-possibly-undefined-in-jsx */}
          {errorMessage}
        </Text>
      )}
    </Box>
  );
}
