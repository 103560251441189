import React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { SelectItem } from '@mentimeter/ragnar-ui/input/select-item';
import { Text } from '@mentimeter/ragnar-ui/text';
import type { FlagT } from '../../types';

const DOT_SIZE = 8;

const OPTION_DEFAULT_VALUE = 'option-default-value';

export function Flag({
  flag,
  onChange,
  onReset,
  variant = 'default',
}: {
  flag: FlagT;
  onChange: (newValue: string) => void;
  onReset: () => void;
  variant?: 'development' | 'default';
}) {
  return (
    <Box
      key={flag.key}
      className="derp"
      width="100%"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      py={1}
      display="grid"
      extend={({ theme }) => ({
        gridTemplateColumns: '2fr 1fr',
        gap: `${theme.space[2]}px`,
      })}
    >
      <Box
        onClick={onReset}
        extend={({ theme }) => ({
          cursor: flag.value ? 'pointer' : 'default',
          ...(flag.value && {
            fontWeight: 'bold',
            '&:before': {
              content: '""',
              display: 'inline-block',
              backgroundColor:
                variant === 'default'
                  ? theme.colors.secondary
                  : theme.colors.notice,
              position: 'absolute',

              width: `${DOT_SIZE}px`,
              height: `${DOT_SIZE}px`,
              borderRadius: `${DOT_SIZE}px`,
              top: `calc(50% - ${DOT_SIZE / 2}px)`,
              left: `-${DOT_SIZE + 4}px`,
            },
          }),
        })}
      >
        <Text
          fontWeight={flag.value ? 'bold' : 'semiBold'}
          color={flag.value ? 'text' : 'textWeaker'}
          extend={() => ({
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          })}
        >
          {flag.displayName}
        </Text>
      </Box>

      <Box>
        <SelectItem
          key={flag.value}
          value={flag.value}
          inputSize="compact"
          data-testid={`flag-select-item-${flag.key}`}
          onChange={(e) => {
            if (e.target.value === OPTION_DEFAULT_VALUE) {
              onReset();
            } else {
              onChange(e.target.value);
            }
          }}
        >
          <SelectItem.Option value={OPTION_DEFAULT_VALUE}>
            default
          </SelectItem.Option>

          {flag.options?.map((option) => (
            <SelectItem.Option key={option}>{option}</SelectItem.Option>
          ))}
        </SelectItem>
      </Box>
    </Box>
  );
}
