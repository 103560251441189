import React from 'react';
import { Button } from '@mentimeter/ragnar-ui/button';

interface BubbleProps {
  onClick: () => void;
  overriddenExperimentsCount: number;
}

export const Bubble = ({
  onClick,
  overriddenExperimentsCount,
}: BubbleProps) => (
  <Button
    variant="primary"
    onClick={onClick}
    extend={({ theme }) => ({
      position: 'fixed',
      paddingLeft: '30px',
      left: '-20px',
      bottom: '100px',
      zIndex: 10,

      height: '40px',
      width: '40px',
      borderRadius: `${theme.radii[3]}px`,
    })}
  >
    {overriddenExperimentsCount}
  </Button>
);
